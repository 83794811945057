import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
  --color-black: #202123;
  --color-soft-black: #212529;
  --color-light-black: #343A40;
  --color-dark-gray: #32373e;
  --color-gray: #747476;
  --color-light-gray: #b4bcc7;
  --color-very-light-gray: #e7ebed;

  --color-accent-yellow: #FFDD14;
  --color-accent-blue: #0080ff;

  --color-white: #FFFFFF;
  --color-white-secondary: #f5f5f5;
  --color-soft-white: #F8F9FA;
  --color-dark-white: #DEE2E6;

  --max-width:1200px;

  --transition: 0.2s ease;
  }

  html {
    font-size:16px;
  }

  a {
    color: var(---color-black);
    transition: .15s ease-out color;
    text-decoration:none;
  }
  a:hover, a:focus {
    color: var(--color-accent-blue);
    transition: .15s ease-in color;
  }

  button {
    display: inline-block;
    margin: 0.5em 0.75em;
    padding: 0 1em;
    border: 1px solid;
    border-radius: 3px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 400;
    cursor: pointer;
    transition: 0.15s ease all;
    color:var(--color-black);
  }

  button:hover, button:focus {
    background-color: transparent;
    color: var(--color-white);
    transform: scale(1.05);
    transition: 0.15s ease all;
  }

  .gatsby-image-wrapper {
    image-rendering: -moz-crisp-edges;
    //Adds a pixelated effect to image's placeholders for images that have not loaded yet
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  body::-webkit-scrollbar {
    width: 12px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
    background-color:var(--color-white);
  }
  body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: var(--color-accent-yellow);
  }
`;

export default GlobalStyles;
