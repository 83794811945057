import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import logo from '../../assets/logo_global.svg'
import Li from '../motion/Li';

const Container = styled.header`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  margin: 0 auto;
  max-width: var(--max-width);
  li {
    padding: 0 1vw;
    margin: 0 1vw;
    font-size: 14px;
    list-style: none;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    z-index: 10000;
  }
  li:nth-child(1) {
    grid-column: 2/3;
  }
  li:hover, li:focus {
    cursor: pointer;
    color: var(--color-accent-blue);
    > a {
      color: var(--color-accent-blue);
    }
  }
  a {
    text-decoration: none;
    white-space: nowrap;
  }
  nav {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    margin-bottom: 3vh;
    li {
      text-align: left;
    }
  }
  .intro {
    display: none;
  }

  @media only screen and (min-width: 800px) {
    grid-auto-flow: column;
    grid-template-columns: 20% 80%;
    width: 90%;
    li {
      padding: 0 2vw;
      margin: 0 1vw;
    }
    li:nth-child(1) {
      grid-column: 1/2;
    }
    nav {
      margin-bottom: 0;
    }
    nav > li {
      text-align: center;
      border-right: 1px solid var(--color-black);
      border-left: 1px solid var(--color-black);
      transition: 0.25s ease border-color;
    }
    nav > li:hover, nav > li:focus {
      border-color: var(--color-accent-blue);
      transition: 0.25s ease border-color;
    }
    img {
      height: 50px;
      transition: 0.5s ease filter;
    }
    img:hover, img:focus {
      filter: drop-shadow(1px 1px 1px var(--color-accent-yellow));
      transition: 0.15s ease filter;
    }
    nav {
      grid-template-columns: repeat(4, 1fr);
    }
    .intro {
      display: grid;
      border: 1px solid;
      background-color: var(--color-accent-yellow);
      border-radius: 3px;
      padding-top: 12px;
      padding-bottom: 12px;
      a {
        vertical-align: middle;
      }
      transition: 0.15s ease background-color;
    }
    .intro:hover, .intro:focus {
      transition: 0.15s ease background-color;
      background-color: white;
    }
  }
  @media only screen and (min-width: 550px) {
    li {
      font-size: 16px;
    }
  }
`;
const SubMenu = styled(motion.li)`
  position: relative;
`;
const SubMenuItems = styled(motion.ul)`
  position: absolute;
  white-space: nowrap;
  margin-top: 10px;
  & > li {
    padding: 0.75em 0;
  }
  background: var(--color-white);
  padding: 1em 2em;
  border-radius: 3px;
  box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.2);
  left: -60%;
  @media only screen and (min-width: 800px) {
    left: 0;
  }
  .sublinks {
    color: var(--color-black);
  }
  .sublinks:hover, .sublinks:focus {
    color: var(--color-accent-blue);
  }
`;
const Logo = styled(motion.div)`
  margin-top: 3vh;
  position: relative;
  cursor: pointer;
  margin: 3vh auto 3vh auto;
  opacity: 0.5;
`;
const variants = {
  initial: {
    opacity: 0,
    y: -50,
    zIndex: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    zIndex: 1000,
  },
  exit: {
    opacity: 0,
  },
};

const Header = ({ links }) => {
  const [isToggled, setToggle] = useState(false);

  return (
    <Container>
      <Link to="/">
        <Logo initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <motion.img
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.97 }}
            height="30px"
            src={logo}
            alt="X-Factor Logo"
          />
        </Logo>
      </Link>
      <nav role="navigation">
        <SubMenu
          onTap={() => setToggle(!isToggled)}
          onHoverStart={() => setToggle(true)}
          onHoverEnd={() => setToggle(false)}
        >
          Programs
          <AnimatePresence>
            {isToggled && (
              <SubMenuItems
                initial="initial"
                animate="animate"
                exit="exit"
                variants={variants}
              >
                {links.map((link) => (
                  <Li className="sublinks" key={link.id}>
                    <Link to={`/${link.slug.current}`}>{link.title}</Link>
                  </Li>
                ))}
              </SubMenuItems>
            )}
          </AnimatePresence>
        </SubMenu>
        <Li>
          <Link to="/blog/">Blog</Link>
        </Li>
        <Li>
          <Link to="/about/">About</Link>
        </Li>
        <Li className="intro">
          <a href="https://crossfitxfactor.wodify.com/OnlineSalesPortal/AppointmentServices.aspx?LocationId=2499&OnlineMembershipId=140509" target="_blank" rel="noopener" rel="noreferrer">Free Intro</a>
        </Li>
      </nav>
    </Container>
  );
};

export default Header;
