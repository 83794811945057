import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import 'normalize.css';
import Cookies from 'js-cookie'
import Slide from '../motion/Slide';
import { motion } from 'framer-motion'

import GlobalStyle from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';

import Header from './Header';
import Footer from './Footer';

const Modal = styled(motion.div)`
  background-color: rgba(21, 109, 255, 0.95);
  div {
    display: grid;
    grid-template-columns: auto auto;
    max-width:1000px;
    margin: auto;
    padding: 1rem 4vw;
  }
  align-items: center;
  p {
    color: white;
    align-self: center;
  }
  span {
    margin: 0.5em 0 0.5em 0.35em;
    color: white;
    justify-self: center;
    font-size: 2rem;
    font-weight: bold;
    transition: var(--transition);
  }
  span:hover {
    color: var(--color-accent-yellow);
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition);
  }
  @media only screen and (max-width:500px) {
    div {
      grid-template-columns:auto;
    }
    span {
      margin:0;
    }
  }
  @media only screen and (min-width:600px) {
    div {
      padding: 1rem 8vw;
    }
  }
  @media only screen and (min-width:800px) {
    div {
      padding: 1rem 12.5vw;
    }
  }
`;

const Layout = ({ children }) => {
  const layoutQuery = useStaticQuery(graphql`
    query WebsiteSettings {
      allSanityProgram(sort: { fields: programOrder }) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      sanitySettings {
        informationPopup {
          displayed
          information
        }
      }
    }
  `);

  const popupExists = layoutQuery.sanitySettings.informationPopup.displayed
  const [open, isOpen] = useState(checkPopup());
  function checkPopup() {
    let isDisplayed = layoutQuery.sanitySettings.informationPopup.displayed

    let isCookieSet = Cookies.get('infoalreadyclosed')
    let display = !isCookieSet && isDisplayed
    return display
  }
  const handleClose = () => {
    Cookies.set('infoalreadyclosed', true, { expires: .04 })
    isOpen(false)
  }

  return (
    <>
      <GlobalStyle />
      <Typography />
      {popupExists && (
        <div style={{position: "fixed", top:0, left: 0, width: "100%", zIndex:20000, }}>
          <Slide
            isActive={open}
            xDirection={1}
            xDistance={0}
            yDirection={-1}
            yDistance={-1000}
            opacity={1}
            duration={0.8}
            delay={0.5}
          >
            <Modal key={`popupInfoModal`}>
              <div>
                <p>{layoutQuery.sanitySettings.informationPopup.information}</p>
                <span role="button" onClick={() => handleClose()}>
                  &times;
                </span>
              </div>
            </Modal>
          </Slide>
        </div>
      )}
      <Header links={layoutQuery.allSanityProgram.nodes} />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
