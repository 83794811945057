import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  display: grid;
  padding: 4.5vh 10vw 2.5vh 10vw;
  grid-auto-flow: row;
  grid-gap: 0.75rem;
  text-align: center;
  .contact-info {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    div {
      display: inline-block;
      padding: 0.5rem 0;
    }
    a {
      align-self: center;
      letter-spacing: 1px;
      line-height: 1.2rem;
    }
  }
  div.socials {
    padding-right: 3rem;
    padding-left: 3rem;
    a {
      margin: 1px 5vw;
    }
  }

  background-color: var(--color-white-secondary);

  h3 {
    margin: 0.5em 0;
  }

  & > a,
  & > p {
    font-size: 0.9rem;
  }
  .tel {
    font-size: 1.1rem;
    font-weight: 500;
  }

  @media only screen and (min-width: 600px) {
    grid-template-columns: 100%;
    padding: 2.5vh 20vw;
    & > *:nth-child(-n + 2) {
      grid-column: 1/2;
    }
    & > *:nth-child(n + 3) {
      grid-column: 2/3;
    }
    & > :nth-child(3) {
      grid-row: 1/2;
    }
    & > :last-child {
      grid-column: 1/3;
      grid-row: 3/4;
    }
    div.socials {
      padding: 0;
      margin-top: 12px;
      & > div {
        display: flex;
        justify-content: center;
      }
      a {
        margin: 1px 2.5vw;
      }
    }
  }
  @media only screen and (min-width: 700px) {
    padding: 2.5vh 15vw;
  }
`;
const SVG = styled.svg`
  fill: var(--color-black);
  transition: 0.25s ease-out all;
  &:hover,
  &:focus {
    transition: 0.15s ease-in all;
    fill: var(--color-accent-blue);
  }
  height: 1.75rem;
  width: 1.75rem;
`;

const Footer = () => {
  const date = new Date();

  return (
    <Container>
      <h3 className="contact">Get in touch</h3>
      <div className="contact-info">
        <div>
          <a href="mailto:info@crossfitxfactor.com">info@crossfitxfactor.com</a>
        </div>
        <div>
          <a className="tel" href="tel:503-227-0130">
            (503) 227-0130
          </a>
        </div>
        <div>
          <a
            href="https://www.google.com/maps/place/CrossFit+X-Factor/@45.5381611,-122.6992912,17z/data=!3m1!4b1!4m5!3m4!1s0x549509e1387bc009:0xa4c53259f51b4910!8m2!3d45.5381611!4d-122.6970972"
            target="_blank"
            rel="noopener noreferrer"
          >
            2202 NW Roosevelt Street
            <br />
            Portland, OR 97210
          </a>
        </div>
        <div className="socials">
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/cfxfactor/"
            >
              <SVG
                stroke-width="0"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </SVG>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/cfxfactor"
            >
              <SVG
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
              </SVG>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/crossfitxfactor?sub_confirmation=1"
            >
              <SVG
                stroke-width="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
              </SVG>
            </a>
          </div>
        </div>
      </div>
      <p className="copyright">Copyright &copy; {date.getFullYear()}</p>
    </Container>
  );
};

export default Footer;
