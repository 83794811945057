import React from 'react';
import { motion } from 'framer-motion';

export default function Fade({ children, ...props }) {
  return (
    <motion.li
      {...props}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {children}
    </motion.li>
  );
}
