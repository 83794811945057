import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function Slide({
  children,
  isActive,
  xDirection = -1,
  xDistance = 100,
  yDirection = -1,
  yDistance = 100,
  duration = 0.3,
  delay = 0,
  opacity = 0,
  zIndex = 1000,
}) {
  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          exit={{
            opacity,
            x: xDirection * -xDistance,
            y: yDirection * -yDistance,
            transition: { duration: 0.5 },
            zIndex,
          }}
          initial={{
            opacity,
            x: xDirection * -xDistance,
            y: yDirection * -yDistance,
            zIndex,
          }}
          animate={{
            opacity: 1,
            x: 0,
            y: 0,
            transition: { duration, delay },
            zIndex,
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
