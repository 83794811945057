import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  html {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: var(--black);
  }
  body {
    -webkit-font-smoothing: antialiased;
    color: var(--color-black);
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  h1 {
    font-size: 2.488rem;
  }
  h2 {
    font-size: 2.075rem;
  }
  h3 {
    font-size: 1.728rem;
  }
  h4 {
    font-size: 1.44rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  p, h6 {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    font-size: 1rem;
  }
  p {
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }
`;

export default Typography;
